import React, { useRef } from 'react';
import Contacto from '../assets/texting.svg';
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';
import { FiSend } from 'react-icons/fi';

const Contact = () => {
  const form = useRef();
  const key = process.env.REACT_APP_KEY;

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_cy5y4vj', 'template_v9r5iqr', form.current, key)
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: '¡Gracias por contactarnos!',
      text: 'Nos estaremos comunicando con usted lo más pronto posible.',
      showConfirmButton: false,
      timer: 5000,
    });
  };
  return (
    <div id='contact' className='w-full bg-white py-16'>
      <div className='max-w-[1240px] mx-auto px-2'>
        <h2
          data-aos='zoom-in-up'
          data-aos-duration='1000'
          className='text-3xl text-slate-600 uppercase text-center'
        >
          contacto
        </h2>
        <h3
          data-aos='zoom-in-up'
          data-aos-duration='1000'
          className='text-5xl mt-6 font-bold text-center'
        >
          Contactanos
        </h3>
        <p
          data-aos='zoom-in-up'
          data-aos-duration='1000'
          className='text-xl my-8 text-gray-500 text-center'
        >
          Por favor, comparte tus datos con nosotros para que podamos ponernos en contacto contigo lo más pronto posible.
        </p>
        <div className='max-w-[1280px] mx-auto grid md:grid-cols-2'>
          <img
            data-aos='fade-right'
            data-aos-duration='1500'
            className='w-[550px] mx-auto sm:h-[400px]'
            src={Contacto}
            alt='/'
          />
          <div data-aos='fade-up' data-aos-duration='2500'>
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <form ref={form} onSubmit={sendEmail}>
                <div className='sm:rounded-md'>
                  <div className='px-4 py-5 bg-white sm:p-6'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
                      <div>
                        <label
                          htmlFor='name'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Nombre
                        </label>
                        <input
                          type='text'
                          name='name'
                          id='name'
                          required
                          autoComplete='off'
                          placeholder='Ingrese su nombre'
                          className='p-3 mt-1 mb-5 border border-gray-300  focus:ring-slate-500 focus:border-slate-500 block w-full shadow-sm sm:text-sm rounded-md'
                        />
                      </div>
                      <div>
                        <label
                          htmlFor='email'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Correo
                        </label>
                        <input
                          type='email'
                          name='email'
                          id='email'
                          required
                          autoComplete='off'
                          placeholder='Ingrese su correo electronico'
                          className='p-3 mt-1 mb-5 border border-gray-300  focus:ring-slate-500 focus:border-slate-500 block w-full shadow-sm sm:text-sm rounded-md'
                        />
                      </div>
                    </div>
                    <div className=' col-span-6 sm:col-span-4'>
                      <label
                        htmlFor='service'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Servicio
                      </label>
                      <input
                        type='text'
                        name='service'
                        id='service'
                        autoComplete='off'
                        required
                        placeholder='Escriba un servicio'
                        className='p-3 mt-1 mb-5 border border-gray-300  focus:ring-slate-500 focus:border-slate-500 block w-full shadow-sm sm:text-sm rounded-md'
                      />
                    </div>
                    <div>
                      <label
                        htmlFor='message'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Mensaje
                      </label>
                      <div className='mt-1'>
                        <textarea
                          id='message'
                          type='text'
                          name='message'
                          autoComplete='off'
                          rows={3}
                          className='p-3 shadow-sm focus:ring-slate-500 focus:border-slate-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Envianos un mensaje'
                          required
                          defaultValue={''}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='px-4  py-3 bg-gray-50 text-center sm:px-6'>
                    <button
                      type='submit'
                      className='w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-slate-900 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 hover:-translate-y-2 duration-700'
                    >
                      <FiSend className='mt-1 mr-2' />
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
