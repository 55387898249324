import React, { useRef, useState, useEffect } from 'react';
import { Galleria } from 'primereact/galleria';

import IMG2 from '../assets/county.jpeg';
import IMG4 from '../assets/countySalon.jpeg';
import IMG5 from '../assets/county2.jpeg';

import IMG1 from '../assets/fuso1.jpeg';
import IMG3 from '../assets/fuso2.jpeg';
import IMG6 from '../assets/fuso3.jpeg';
import IMG7 from '../assets/fuso4.jpeg';
import IMG8 from '../assets/fuso5.jpeg';

const Units = () => {
  const data = [
    {
      itemImageSrc: IMG1,
      thumbnailImageSrc: IMG1,
      alt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
      itemImageSrc: IMG2,
      thumbnailImageSrc: IMG2,
      alt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
      itemImageSrc: IMG3,
      thumbnailImageSrc: IMG3,
      alt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
      itemImageSrc: IMG8,
      thumbnailImageSrc: IMG8,
      alt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
      itemImageSrc: IMG6,
      thumbnailImageSrc: IMG6,
      alt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
      itemImageSrc: IMG7,
      thumbnailImageSrc: IMG7,
      alt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
      itemImageSrc: IMG4,
      thumbnailImageSrc: IMG4,
      alt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
    {
      itemImageSrc: IMG5,
      thumbnailImageSrc: IMG5,
      alt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    },
  ];
  const [images, setImages] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const galleria3 = useRef(null);
  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  const itemTemplate = (item) => {
    return (
      <img
        src={item.itemImageSrc}
        onError={(e) =>
          (e.target.src =
            'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
        }
        alt={item.alt}
        style={{ width: '100%', display: 'block' }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.thumbnailImageSrc}
        onError={(e) =>
          (e.target.src =
            'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
        }
        alt={item.alt}
        style={{ display: 'block' }}
      />
    );
  };
  useEffect(() => {
    setImages(data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id='units' className='w-full py-16'>
      <div className='max-w-[1240px] mx-auto px-2'>
        <h2
          data-aos='zoom-in-up'
          data-aos-duration='1000'
          className='text-3xl pt-2 text-slate-600 uppercase text-center'
        >
          unidades
        </h2>
        <h3
          data-aos='zoom-in-up'
          data-aos-duration='1000'
          className='text-5xl font-bold text-center py-6'
        >
          Nuestras Unidades
        </h3>
        <p
          data-aos='zoom-in-up'
          data-aos-duration='1000'
          className='text-xl mb-8 text-gray-500 text-center'
        >
          Nuestras unidades se mantienen con el más alto nivel de cuidado, comodidad y limpieza.
        </p>
        <div>
          <Galleria
            ref={galleria3}
            value={images}
            responsiveOptions={responsiveOptions}
            numVisible={7}
            style={{ maxWidth: '850px' }}
            activeIndex={activeIndex}
            onItemChange={(e) => setActiveIndex(e.index)}
            circular
            fullScreen
            showItemNavigators
            showThumbnails={false}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
          />

          <div
            className='grid grid-cols-1 sm:grid-cols-4 gap-x-1 gap-y-1 px-4 sm:px-0 pt-12 sm:pt-10 text-black'
            style={{ maxWidth: '1240px' }}
          >
            {images &&
              images.map((image, index) => {
                let imgEl = (
                  <img
                    src={image.thumbnailImageSrc}
                    className='h-[230px] w-full rounded-sm'
                    onError={(e) =>
                      (e.target.src =
                        'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
                    }
                    alt={image.alt}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setActiveIndex(index);
                      galleria3.current.show();
                    }}
                  />
                );

                return (
                  <div className='col-3' key={index}>
                    {imgEl}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Units;
