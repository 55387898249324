import React from "react";
import { HiOutlineBadgeCheck } from "react-icons/hi";
import Hero from "../assets/world.svg";
const About = () => {
  return (
    <div id="about" className="w-full pb-40 mt-20 h-full bg-white px-8">
      <h2
        data-aos="zoom-in-up"
        data-aos-duration="1000"
        className="text-3xl pt-16 text-slate-600 uppercase text-center"
      >
        nosotros
      </h2>
      <h3
        data-aos="zoom-in-up"
        data-aos-duration="1000"
        className="text-5xl font-bold text-center py-6 "
      >
        Sobre Nosotros
      </h3>
      <p
        data-aos="zoom-in-up"
        data-aos-duration="1000"
        className="text-xl sm:mb-20 mb-6 text-gray-500 text-center"
      >
        Descubre más acerca de nuestro proposito y los valores que guían a nuestra empresa de transporte.
      </p>
      <div className="max-w-[1280px] mx-auto grid md:grid-cols-2">
        <img
          data-aos="fade-right"
          data-aos-duration="1500"
          className="w-[550px] mx-auto sm:h-[400px]"
          src={Hero}
          alt="/"
        />
        <div
          data-aos="fade-up"
          data-aos-duration="2500"
          className="flex flex-col justify-center"
        >
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold pt-6 sm:pt-0 pb-4">
            Nuestro proposito
          </h1>

          <p className="text-justify">
            Nuestra empresa tiene una firme dedicación a ofrecer a nuestros
            clientes servicios de alta calidad y personalizados. Nuestro
            compromiso radica en cumplir y superar las expectativas de las
            personas, al mismo tiempo que nos esforzamos por garantizar la
            máxima comodidad y seguridad en todas nuestras unidades.
          </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-4">
            Nuestros valores
          </h1>
          <p className="flex text-justify mt-3">
            <i>
              <HiOutlineBadgeCheck className="text-slate-900 text-xl w-6 mx-1" />
            </i>
            Puntualidad
          </p>
          <p className="flex text-justify mt-3">
            <i>
              <HiOutlineBadgeCheck className="text-slate-900 text-xl w-6 mx-1" />
            </i>
            Respeto
          </p>
          <p className="flex text-justify mt-3">
            <i>
              <HiOutlineBadgeCheck className="text-slate-900 text-xl w-6 mx-1" />
            </i>
            Limpieza
          </p>
          <button className="bg-slate-900 text-white w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3 hover:-translate-y-2 duration-700">
            Contactanos
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
