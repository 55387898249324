import { useState } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link, animateScroll as scroll } from 'react-scroll';
import logo from '../assets/logovelcabwhite.svg';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const handleClose = () => setNav(!nav);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div className='w-screen h-[70px] sm:h-[80px] z-30 bg-slate-900 fixed drop-shadow-sm'>
      <div className='sm:px-20  px-0 flex justify-between items-center w-full h-full'>
        <div className='flex items-center sm:ml-[15rem]'>
          <div className='w-full text-3xl text-white font-bold sm:text-4xl '>
            <img
              className='sm:w-[200px] cursor-pointer w-[150px] text-white  sm:mx-0 mx-2'
              src={logo}
              onClick={scrollToTop}
              alt='/'
            />
          </div>
        </div>
        <div>
          <ul className='hidden md:flex sm:mr-[15rem] text-xl'>
            <Link
              activeClass='active'
              to='home'
              spy={true}
              smooth={true}
              duration={500}
              className='p-4 cursor-pointer text-white hover:text-[#6C63FF] rounded-[1rem]'
            >
              Inicio
            </Link>
            <Link
              activeClass='active'
              to='about'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className='p-4 cursor-pointer text-white hover:text-[#6C63FF] rounded-[1rem]'
            >
              Nosotros
            </Link>
            <Link
              activeClass='active'
              to='services'
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              className='p-4 cursor-pointer text-white hover:text-[#6C63FF] rounded-[1rem]'
            >
              Servicios
            </Link>
            <Link
              activeClass='active'
              to='units'
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              className='p-4 cursor-pointer text-white hover:text-[#6C63FF] rounded-[1rem]'
            >
              Unidades
            </Link>
            <Link
              activeClass='active'
              to='contact'
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              className='p-4 cursor-pointer text-white hover:text-[#6C63FF] rounded-[1rem]'
            >
              Contacto
            </Link>
          </ul>
        </div>
        <div className='md:hidden mr-4' onClick={handleClick}>
          {!nav ? (
            <MenuIcon className='text-white w-5' />
          ) : (
            <XIcon className='text-white w-5' />
          )}
        </div>
      </div>

      <ul
        className={
          !nav
            ? 'sm:hidden absolute left-[-100%] ease-in-out duration-100'
            : 'absolute left-0 bg-slate-800 w-[100%] h-[100vh]  sm:hidden px-2 ease-in-out duration-500'
        }
      >
        <li className='py-6 text-xl border-b-2 text-center text-zinc-100 border-slate-600 w-full'>
          <Link
            activeClass='active'
            spy={true}
            onClick={handleClose}
            to='home'
            smooth={true}
            duration={500}
          >
            Inicio
          </Link>
        </li>
        <li className='py-6 text-xl border-b-2 text-center text-zinc-100 border-slate-600 w-full  ease-in-out duration-1000'>
          <Link
            activeClass='active'
            spy={true}
            onClick={handleClose}
            to='about'
            smooth={true}
            offset={-50}
            duration={500}
          >
            Nosotros
          </Link>
        </li>
        <li className='py-6 text-xl border-b-2 text-center text-zinc-100 border-slate-600 w-full'>
          <Link
            activeClass='active'
            spy={true}
            onClick={handleClose}
            to='services'
            smooth={true}
            offset={-50}
            duration={500}
          >
            Servicios
          </Link>
        </li>
        <li className='py-6 text-xl border-b-2 text-center text-zinc-100 border-slate-600 w-full'>
          <Link
            activeClass='active'
            spy={true}
            onClick={handleClose}
            to='units'
            smooth={true}
            offset={-50}
            duration={500}
          >
            Unidades
          </Link>
        </li>
        <li className='py-6 text-xl w-full border-slate-600 text-center text-zinc-100'>
          <Link
            activeClass='active'
            spy={true}
            onClick={handleClose}
            to='contact'
            smooth={true}
            offset={-50}
            duration={500}
          >
            Contacto
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
