import React from "react";
import { FaFacebook } from "react-icons/fa";
const Footer = () => {
  return (
    <div className="w-full mt-24 bg-slate-900 text-gray-300 py-y px-2">
      <div className="max-w-[1240px] mx-auto grid grid-cols-1 lg:grid-cols-3 border-b-2 border-gray-600 py-8">
        <div>
          <h1 className="mx-2 sm:mx-0 w-full text-3xl font-bold text-zinc-100">
            VELCAB TOURS.
          </h1>
          <p className="mx-2 sm:mx-0 py-4 text-justify">
            En nuestra empresa, nos hemos dedicado siempre a brindar servicios
            personalizados de alta calidad a nuestros clientes. Nuestro
            compromiso es superar las expectativas de las personas, al mismo
            tiempo que garantizamos la máxima comodidad y seguridad en todas
            nuestras unidades.
          </p>
        </div>

        <div className="mx-2 sm:ml-40">
          <h6 className="font-bold uppercase pt-2">Navegacion</h6>
          <ul className="py-4">
            <li className="py-1">Inicio</li>
            <li className="py-1">Nosotros</li>
            <li className="py-1">Servicios</li>
            <li className="py-1">Unidades</li>
            <li className="py-1">Contacto</li>
          </ul>
        </div>
        <div className="mx-2 sm:mx-0">
          <h6 className="font-bold uppercase pt-2">Informacion de contacto</h6>
          <ul className="py-4">
            <li className="flex py-1">
              <div className="mr-2 pr-1">
                <strong>Información:</strong>
              </div>
              <div>info@velcabtours.com</div>
            </li>
            <li className="flex py-1">
              <div className="mr-2">
                <strong>Telefonos: </strong>
              </div>
              <div>+51 997 158 032 / +51 981 448 824</div>
            </li>
            <li className="flex py-1">
              <div className="mr-2">
                <strong>Siguenos en: </strong>
              </div>
              <div className="flex sm:w-[90px] pt-0 text-4xl">
                <a
                  href="https://www.facebook.com/VelCabTours?mibextid=ZbWKwL"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FaFacebook className="cursor-pointer mx-1" />
                </a>
                {/* <a
                  href="https://www.instagram.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FaInstagram className="cursor-pointer mx-1" />
                </a> */}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="py-4">
          @2022 VelCabtours S.A.C.Todos los derechos reservados
        </p>
      </div>
    </div>
  );
};

export default Footer;
