import React from 'react';
import bus from '../assets/Bus.svg';
import school from '../assets/school.svg';
import beach from '../assets/beach.svg';
import { Link } from 'react-scroll';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { ArrowSmRightIcon } from '@heroicons/react/outline';
import supportImg from '../assets/about-mission.jpg';

const Services = () => {
  return (
    <div id='services' className='w-full marker:mt-24'>
      <div className='w-full h-[700px] bg-gray-900/90 absolute'>
        <img
          className='w-full h-full object-cover mix-blend-overlay'
          src={supportImg}
          alt='/'
        />
      </div>
      <div className='max-w-[1240px] mx-auto text-white relative'>
        <div className='px-4 py-12'>
          <h2
            data-aos='zoom-in-up'
            data-aos-duration='1000'
            className='text-3xl  text-slate-300 uppercase text-center'
          >
            Servicios
          </h2>
          <h3
            data-aos='zoom-in-up'
            data-aos-duration='1000'
            className='text-5xl font-bold py-6 text-center'
          >
            Nuestros Servicios
          </h3>
          <p
            data-aos='zoom-in-up'
            data-aos-duration='1000'
            className='py-4 text-xl text-center text-slate-300'
          >
            Te presentamos una visión general de los diversos servicios que proporcionamos.
          </p>
        </div>
        <div
          className='grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'
          data-aos='fade-up'
          data-aos-duration='1000'
        >
          <div className='bg-white rounded-xl shadow-2xl hover:-translate-y-6 duration-700'>
            <div className='p-8 '>
              <div className='w-16 p-4 bg-slate-900 text-white rounded-lg mt-[-4rem] '>
                <img src={bus} alt='/' />
              </div>
              <h3 className='font-bold text-2xl my-6'>Servicio Personal</h3>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Paseo Familiar.
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Retiros Espirituales.
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Movilidad Funeraria.
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Transporte Ejecutivo.
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Transporte Privado.
              </p>
            </div>
            <div className='bg-slate-100 pl-8 py-4'>
              <Link
                activeClass='active'
                spy={true}
                to='contact'
                smooth={true}
                offset={-50}
                duration={500}
              >
                <p className='flex items-center cursor-pointer text-slate-600'>
                  Contactanos <ArrowSmRightIcon className='w-5 ml-2' />
                </p>
              </Link>
            </div>
          </div>
          <div className='bg-white rounded-xl shadow-2xl hover:-translate-y-6 duration-700'>
            <div className='p-8'>
              <div className='w-16 p-4 bg-slate-900 text-white rounded-lg mt-[-4rem]'>
                <img src={school} alt='/' />
              </div>
              <h3 className='font-bold text-2xl my-6'>Servicio Escolar</h3>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Traslados a clubes
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Traslados a excursiones
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Traslados recreativos.
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Visitas a museos
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Traslados a campamentos
              </p>
            </div>
            <div className='bg-slate-100 pl-8 py-4'>
              <Link
                activeClass='active'
                spy={true}
                to='contact'
                smooth={true}
                offset={-50}
                duration={500}
              >
                <p className='flex items-center cursor-pointer text-slate-600 '>
                  Contactanos <ArrowSmRightIcon className=' w-5 ml-2 ' />
                </p>
              </Link>
            </div>
          </div>
          <div className='bg-white rounded-xl shadow-2xl hover:-translate-y-6 duration-700'>
            <div className='p-8 '>
              <div className='w-16 p-4 bg-slate-900 text-white rounded-lg mt-[-4rem]'>
                <img src={beach} alt='/' />
              </div>
              <h3 className='font-bold text-2xl my-6'>Servicio Turístico</h3>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                City Tours y Full Days.
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Paseos, excursiones y traslados
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Servicio a disposicion por horas.
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Traslados a aeropuertos y hoteles.
              </p>
              <p className='flex text-justify mt-3'>
                <i>
                  <HiOutlineCheckCircle className='text-slate-900 text-xl w-6 mx-1' />
                </i>
                Transporte turistico a nivel nacional
              </p>
            </div>
            <div className='bg-slate-100 pl-8 py-4'>
              <Link
                activeClass='active'
                spy={true}
                to='contact'
                smooth={true}
                offset={-50}
                duration={500}
              >
                <p className='flex items-center cursor-pointer text-slate-600'>
                  Contactanos <ArrowSmRightIcon className='w-5 ml-2' />
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
