import React from 'react';
import Typed from 'react-typed';
import { BsWhatsapp } from 'react-icons/bs';
import { Link } from 'react-scroll';
import bgImg from '../assets/map.svg';
const Hero = () => {
  return (
    <div
      id='home'
      className='w-full h-screen bg-slate-900 flex flex-col justify-between'
    >
      <div className='grid md:grid-cols-2 max-w-[1240px] m-auto'>
        <div className='flex flex-col justify-center md:items-start w-full px-2 py-8'>
          <p className='sm:text-3xl text-xl text-white text-center'>
            Servicio
            <Typed
              className='md:text-3xl sm:text-4xl text-xl sm:pl-2 pl-1'
              strings={['Escolar.', 'Personal.', 'Turistico.']}
              typeSpeed={40}
              backSpeed={50}
              loop
            />
          </p>

          <h1 className='py-3 md:text-left text-center text-5xl text-white md:text-7xl font-bold'>
            Transportes VelCab Tours
          </h1>
          <p className='text-xl text-white sm:text-left text-center'>
            Tu movildad de confianza.
          </p>

          <Link
            activeClass='active'
            to='contact'
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className='text-center cursor-pointer  mx-20 sm:mx-0 bg-slate-800 hover:bg-slate-500 font-medium text-white rounded-md py-3 px-6 sm:w-[60%] my-4 hover:-translate-y-2 duration-700'
          >
            Contactanos
          </Link>
        </div>
        <div>
          <img className='sm:w-full w-[80%] sm:mx-0 mx-6' src={bgImg} alt='/' />
        </div>
      </div>
      <a
        href='https://api.whatsapp.com/send?phone=51939811513'
        className='fixed w-[60px] h-[60px] rounded-[50%] bg-[#25d366] z-20 bottom-0 right-0 mx-4 my-4 drop-shadow-md hover:scale-125 duration-500 animate-bounce'
        rel='noreferrer'
        target='_blank'
      >
        <BsWhatsapp className='text-white w-[35px] h-[40px] mx-auto mt-[10px]' />
      </a>
    </div>
  );
};

export default Hero;
