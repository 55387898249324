import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import About from './components/About';
import Units from './components/Units';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Contact from './components/Contact';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init();
function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Services />
      <Units />
      <Contact />
      <Footer />
    </div>
  );
}
export default App;
